import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../Helpers/Constant';

const SellerNewPassword = ({ phone, token }) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handelSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (password1.length && password2.length > 5) {
      if (password1 === password2) {
        fetch(`${url}/out/api/auth/seller/forgot/password`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token,
            phone,
            password: password2,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              alert(result.message);
              navigate('/');
            }
          });
      } else {
        setError('password does not match');
        setTimeout(() => {
          setError('');
        }, 5000);
        setIsLoading(false);
      }
    } else {
      setError('password must be at least 6 characters');
      setTimeout(() => {
        setError('');
      }, 5000);
      setIsLoading(false);
    }
  };
  return (
    <div>
      {' '}
      <div className='freelancing-login-page-queen'>
        <div className='py-4'>
          <div className='text-center mb-5'>
            <div>
              <img
                className='freelancing-img'
                src='/assets/freelancing/logo.png'
                alt=''
              />
              <p className='freelancing-text '>Q-Freelancing</p>
              {/* banner image */}
            </div>
          </div>

          <div className='mt-5 pb-2 login-section px-4'>
            <div className='text-center  pt-1 '>
              <h4>Change password</h4>
            </div>
            <div className='my-5 px-1'>
              <form onSubmit={handelSubmit}>
                <div>
                  <div>
                    <label className='fw-bold mb-1'>Enter new password</label>
                    <input
                      placeholder='Enter New Password'
                      type={passwordType}
                      className='Freelancing-login-input w-100'
                      onChange={(e) => setPassword1(e.target.value)}
                      required
                    />
                  </div>
                  <div className='mt-3'>
                    <label className='fw-bold mb-1'>Retype password</label>
                    <input
                      placeholder='Retype Password'
                      type={passwordType}
                      className='Freelancing-login-input w-100'
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                    />
                  </div>

                  <div className='text-start ps-2 mt-2'>
                    <input
                      type='checkbox'
                      onClick={() => {
                        passwordType === 'password'
                          ? setPasswordType('text')
                          : setPasswordType('password');
                      }}
                    />
                    <span
                      style={{
                        marginLeft: '8px',
                      }}
                    >
                      Show password
                    </span>
                  </div>
                  {error && (
                    <div className='mt-3'>
                      {error && (
                        <p className='text-center text-danger'>{error}</p>
                      )}
                    </div>
                  )}

                  <div className='text-center mt-2'>
                    {isLoading ? (
                      <input
                        type='submit'
                        className='login-submit '
                        value='Submiting...'
                        disabled
                      />
                    ) : (
                      <input
                        type='submit'
                        className='login-submit mb-2'
                        value='Submit'
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerNewPassword;
