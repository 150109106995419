import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { useFreelancingAuth } from '../QFreelancing/FreelancingAuthPage/FreelancingaAuthContext';
import SyncLoader from 'react-spinners/SyncLoader';
import { url } from '../Helpers/Constant';
import { districts } from '../Helpers/districts';

const Register = () => {
  const { setFreelancingAuth } = useFreelancingAuth();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [divisionID, setDivisionID] = useState('');
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setLoading(true);
    if (data.phone.length === 11 && data.phone.startsWith('01')) {
      if (data.password.length > 5) {
        fetch(`${url}/out/api/auth/seller/registation`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setFreelancingAuth(data.data);
              navigate('/');
              setLoading(false);
            } else {
              setError(data.message);
              setLoading(false);
              setTimeout(() => {
                setError('');
              }, 5000);
            }
          });
      } else {
        setError('password must be at least 6 characters');
        setLoading(false);
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } else {
      setError('Invalid Phone Number');
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <div className='freelancing-login-page'>
      <div className='py-4'>
        <div>
          <div className='text-center'>
            <img
              className='freelancing-img'
              src='/assets/freelancing/logo.png'
              alt=''
            />
            <p className='freelancing-text'>Q-Freelancing</p>
            <div className='mt-5'>
              <img src='/assets/freelancing/freelancing.png' alt='' />
            </div>
          </div>

          <div className='text-center my-4 '>
            <h4>Register with information</h4>
          </div>

          <div className=' px-4'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label className='fw-bold mb-1'>Enter your name</label>
                <input
                  placeholder='Enter Your Name'
                  type='text'
                  className='Freelancing-login-input w-100 '
                  {...register('name')}
                  required
                />
              </div>
              <div className='my-3'>
                <label className='fw-bold mb-1'>Enter your number</label>
                <input
                  placeholder='Enter Your Phone Number'
                  type='number'
                  className='Freelancing-login-input w-100 '
                  {...register('phone')}
                  required
                />
              </div>
              <div>
                <label className='fw-bold mb-1'>Enter your email</label>
                <input
                  placeholder='Enter Your Email Address'
                  type='email'
                  className='Freelancing-login-input w-100 '
                  {...register('email')}
                  required
                />
              </div>
              <div>
                <div className='mt-2'>
                  <label className='fw-bold mb-1'> Select your division</label>

                  <select
                    onClick={(e) => setDivisionID(e.target.value)}
                    className='Freelancing-login-input w-100 '
                    required
                    {...register('division')}
                  >
                    <option value=''>Select Division</option>
                    <option value='Barisal'>Barisal</option>
                    <option value='Chittagong'>Chittagong</option>
                    <option value='Dhaka'>Dhaka</option>
                    <option value='Khulna'>Khulna</option>
                    <option value='Mymensingh'>Mymensingh</option>
                    <option value='Rajshahi'>Rajshahi</option>
                    <option value='Rangpur'>Rangpur</option>
                    <option value='Rajshahi'>Sylhet</option>
                  </select>
                </div>

                {divisionID && (
                  <div className='mt-2'>
                    <label className='fw-bold mb-1'>Select your city</label>

                    <select
                      className='Freelancing-login-input w-100 '
                      required
                      {...register('city')}
                    >
                      {districts.map(
                        (dis) =>
                          divisionID === dis.division_name && (
                            <option value={dis.name} key={dis.id}>
                              {dis.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                )}
              </div>
              <div className='pt-2'>
                <label className='fw-bold mb-1'>Enter your address</label>

                <input
                  {...register('address')}
                  required
                  placeholder='Enter Your Address'
                  className='Freelancing-login-input w-100 '
                  type='text'
                />
              </div>
              <div className='mt-2'>
                <label className='fw-bold mb-1'>Enter your post code</label>

                <input
                  {...register('post_code')}
                  required
                  placeholder=' Enter Your Post Code'
                  className='Freelancing-login-input w-100 '
                  type='number'
                />
              </div>
              <div className='my-3'>
                <label className='fw-bold mb-1'>Enter your password</label>
                <input
                  placeholder='Enter Your Password'
                  type='password'
                  className='Freelancing-login-input w-100'
                  {...register('password')}
                  required
                />
              </div>
              <div>
                <p>
                  Already have account?
                  <Link
                    className='text-decoration-none pointer ps-1'
                    to='/login'
                  >
                    Login
                  </Link>
                </p>
              </div>

              {error && (
                <div className='mt-3'>
                  {error && <p className='text-center text-danger '>{error}</p>}
                </div>
              )}

              <div className='text-center'>
                {loading ? (
                  <div className='pt-3'>
                    <SyncLoader color='#f66391' />
                  </div>
                ) : (
                  <input
                    type='submit'
                    className='login-submit '
                    value='Register'
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
