import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../Helpers/Constant';

const SellerOtpMatch = ({ setToken, phone }) => {
  const [otpNumber, setOtpNumber] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handelSellerLogIn = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (otpNumber.length === 6) {
      fetch(`${url}/api/otp/match`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          otp_creds: {
            phone: phone,
            otp: otpNumber,
            type: 'forget',
          },
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setToken(data.token);
            navigate('/forgot-password/seller-newpassword');
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setError(data.message);
            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });
    } else {
      setIsLoading(false);
      setError('Invalid otp Number');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <>
      <div className='freelancing-login-page-queen'>
        <div className='py-4'>
          <div className='text-center mb-5'>
            <div>
              <img
                className='freelancing-img'
                src='/assets/freelancing/logo.png'
                alt=''
              />
              <p className='freelancing-text '>Q-Freelancing</p>
              {/* banner image */}
            </div>
          </div>

          <div className='mt-5 pb-2 login-section px-4'>
            <div className='text-center  pt-1 '>
              <h4>Enter OTP</h4>
            </div>
            <div className='my-5 px-1'>
              <form onSubmit={handelSellerLogIn}>
                <div>
                  <div>
                    <label className='fw-bold mb-1'>Enter OTP number</label>
                    <input
                      placeholder='Enter OTP Number'
                      type='text'
                      className='Freelancing-login-input w-100'
                      onChange={(e) => setOtpNumber(e.target.value)}
                      required
                    />
                  </div>

                  {error && (
                    <div className='mt-3'>
                      {error && (
                        <p className='text-center text-danger'>{error}</p>
                      )}
                    </div>
                  )}

                  <div className='text-center mt-2'>
                    {isLoading ? (
                      <input
                        type='submit'
                        className='login-submit '
                        value='Submiting...'
                        disabled
                      />
                    ) : (
                      <input
                        type='submit'
                        className='login-submit mb-2'
                        value='Submit'
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerOtpMatch;
