import React, { useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { url } from '../../../../Helpers/Constant';

import SellerNewPassword from '../SellerNewPassword/SellerNewPassword';
import SellerOtpMatch from '../SellerOtpMatch/SellerOtpMatch';

const ForgotPassword = () => {
  const [phone, setPhone] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handelSellerLogIn = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (phone.startsWith('01') && phone.length === 11) {
      fetch(`${url}/api/otp/send/forget`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ phone, user: 'seller' }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            navigate('/forgot-password/sellerotpmatch');
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setError(data.message);
            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });
    } else {
      setIsLoading(false);
      setError('Invalid Phone Number');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <div className='freelancing-login-page-queen'>
              <div className='py-4'>
                <div className='text-center mb-5'>
                  <div>
                    <img
                      className='freelancing-img'
                      src='/assets/freelancing/logo.png'
                      alt=''
                    />
                    <p className='freelancing-text '>Q-Freelancing</p>
                    {/* banner image */}
                  </div>
                </div>

                <div className='mt-5 pb-2 login-section px-4'>
                  <div className='text-center pt-1 '>
                    <h4>Forget password</h4>
                  </div>
                  <div className='my-5 px-1'>
                    <form onSubmit={handelSellerLogIn}>
                      <div>
                        <div>
                          <label className='fw-bold mb-1'>
                            Enter your phone number
                          </label>
                          <input
                            placeholder='01XXXXXXXXX'
                            type='text'
                            className='Freelancing-login-input w-100'
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </div>

                        <div className=' mt-3'>
                          Back to
                          <Link
                            to='/login'
                            className='text-decoration-none ps-1'
                          >
                            login
                          </Link>
                        </div>

                        {error && (
                          <div className='mt-3'>
                            {error && (
                              <p className='text-center text-danger'>{error}</p>
                            )}
                          </div>
                        )}

                        <div className='text-center mt-2'>
                          {isLoading ? (
                            <input
                              type='submit'
                              className='login-submit '
                              value='Submiting...'
                              disabled
                            />
                          ) : (
                            <input
                              type='submit'
                              className='login-submit mb-2'
                              value='Forget password'
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        ></Route>
        <Route
          path='/sellerotpmatch'
          element={<SellerOtpMatch phone={phone} setToken={setToken} />}
        ></Route>
        <Route
          path='/seller-newpassword'
          element={<SellerNewPassword phone={phone} token={token} />}
        ></Route>
      </Routes>
    </div>
  );
};

export default ForgotPassword;
