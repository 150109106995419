import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { url } from '../../Helpers/Constant';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Spinner } from 'react-bootstrap';

const FreelancingGig = () => {
  const [allGigSeller, setAllGigSeller] = useState([]);
  const { freelancingAuth } = useFreelancingAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (freelancingAuth.id) {
      fetch(`${url}/out/api/gig/get/all/seller/${freelancingAuth.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setAllGigSeller(data.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
    }
  }, [freelancingAuth.id]);

  return (
    <>
      <div className='header-div'>
        <h6 className='header-text'>Gig</h6>
      </div>

      <div className='gig-body-wraper'>
        {isLoading ? (
          <div className='text-center mt-5'>
            <Spinner animation='border' style={{ color: '#e9313a' }} />
          </div>
        ) : (
          <div>
            {allGigSeller?.length ? (
              <div>
                {allGigSeller.map((gig) => {
                  const { ft_img, price, status, title, gig_id } = gig;

                  return (
                    <div key={gig_id} className='mx-2 mt-4 bg-white'>
                      <Link
                        className=' text-decoration-none'
                        to={`/freelancing-account/freelancing-single-gig/${gig_id}`}
                      >
                        <div>
                          <LazyLoadImage
                            effect='blur'
                            width='100%'
                            style={{
                              objectFit: 'contain',
                            }}
                            height={250}
                            src={`${url}/get/image/freelancing_gig_files/${ft_img}`}
                          />

                          <div className='d-flex justify-content-between align-items-center gig-detail ps-1 py-2 '>
                            <div className='d-flex gap-2'>
                              <div>
                                {freelancingAuth.photo ? (
                                  <img
                                    className='rounded-circle'
                                    width={30}
                                    height={30}
                                    src={`${url}/get/image/freelancing_seller_files/${freelancingAuth.photo}`}
                                    alt=''
                                  />
                                ) : (
                                  <img
                                    className='rounded-circle'
                                    width={30}
                                    height={30}
                                    src='/assets/freelancing/profile-photo.png'
                                    alt=''
                                  />
                                )}
                              </div>
                              <div className='text-white'>
                                <p className='gig title'>{title}</p>
                                {status === 'Approved' && (
                                  <h6 className='text-success fw-bold mt-1'>
                                    {status}
                                  </h6>
                                )}
                                {status === 'Pending' && (
                                  <h6 className='text-warning fw-bold mt-1'>
                                    {status}
                                  </h6>
                                )}
                              </div>
                            </div>
                            <div>
                              <h6 className='text-white pe-1 gig-price'>
                                STARTING AT{' '}
                                <span className='fw-bold'>{price} tk</span>{' '}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                <div className='text-center mb-5 pb-4'>
                  <Link to='/freelancing-account/create-gig'>
                    <img
                      className='create-gig-link-img'
                      width={125}
                      src='/assets/freelancing/createGig.png'
                      alt=''
                    />
                  </Link>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-column align-items-center pt-5'>
                <div className='gig-create-text text-center'>
                  <p>You don’t have a gig, Please Create Gig</p>
                </div>
                <div className='mt-5'>
                  <Link to='/freelancing-account/create-gig'>
                    <img
                      className='create-gig-img'
                      src='/assets/freelancing/createGig.png'
                      alt=''
                    />
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FreelancingGig;
