import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { url } from '../../Helpers/Constant';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import SellerSmsSection from './SellerSmsSection';

const SellerSms = () => {
  const [singleConv, setSingleConv] = useState({});
  const { freelancingAuth } = useFreelancingAuth();
  const { buyer_id } = useParams();

  useEffect(() => {
    fetch(`${url}/out/api/convo/get/${buyer_id}/${freelancingAuth.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSingleConv(data.data);
        }
      });
  }, [buyer_id, freelancingAuth.id]);

  return (
    <div className='chat-wapper '>
      <div className='freelancing-show-message ps-3 '>
        <div className='d-flex align-items-center gap-1 py-2 '>
          <div>
            <img
              className='rounded-circle'
              width={40}
              height={40}
              src={
                singleConv?.buyer_photo
                  ? `${url}/get/image/freelancing_buyer_files/${singleConv?.buyer_photo}`
                  : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
              }
              alt=''
            />
          </div>
          <div>
            <h5>{singleConv?.buyer_name}</h5>
          </div>
        </div>
      </div>
      <div className='seller-sms-wapper'>
        <SellerSmsSection singleConv={singleConv}></SellerSmsSection>
      </div>
    </div>
  );
};

export default SellerSms;
