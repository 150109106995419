import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Col, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import AdminFreelancing from './Components/QFreelancing/AdminFreelancing/AdminFreelancing';
import Register from './Components/Register/Register';
import ForgotPassword from './Components/QFreelancing/FreelancingAuthPage/ForgotPassword/ForgotPassword/ForgotPassword';
import { RegisterWithQueenAccount } from './Components/RegisterWithQueenAccount/RegisterWithQueenAccount';
import FreelancingAccount from './Components/QFreelancing/QFreelancingComponents/FreelancingAccount';

function App() {
  return (
    <div className='px-0 px-md-3'>
      <Row>
        <Col className='px-0 mx-md-auto' md={4} xxl={3}>
          <Routes>
            <Route path='/*' element={<FreelancingAccount />}></Route>
            <Route
              path='/freelancing-account/*'
              element={<FreelancingAccount />}
            ></Route>
            <Route path='/login' element={<AdminFreelancing />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route
              path='/forgot-password/*'
              element={<ForgotPassword />}
            ></Route>
            <Route
              path='/registerwith-queenaccount'
              element={<RegisterWithQueenAccount />}
            ></Route>
          </Routes>
        </Col>
      </Row>
    </div>
  );
}

export default App;
