import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import { useForm } from 'react-hook-form';
import { districts } from '../../Helpers/districts';
import { url } from '../../Helpers/Constant';

const ProfileInfoEdit = ({ show, handleClose }) => {
  const { freelancingAuth, setFreelancingAuth } = useFreelancingAuth();
  const { register, handleSubmit } = useForm();
  const [divisionID, setDivisionID] = useState(freelancingAuth.division);
  const [image, setImage] = useState('');

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key) {
        formData.append(key, data[key]);
      }
    });

    if (image) formData.append('photo', image);
    fetch(`${url}/out/api/seller/update/profile/${freelancingAuth.id}`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setFreelancingAuth({ ...freelancingAuth, ...result.data });
          alert(result.message);
          handleClose();
        }
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-4 d-flex align-items-center gap-5 ps-5'>
            <div className='position-relative text-success '>
              <label htmlFor='image' className='pointer'>
                <div className='gig-up-image-input'>
                  <div className='mx-3 mt-2 '>
                    <img
                      className='mx-2'
                      src='/assets/freelancing/addImage.png'
                      alt=''
                    />
                    <h6>Add new</h6>
                  </div>
                </div>
                <input
                  className='d-none'
                  type='file'
                  id='image'
                  required
                  accept='.jpg, .png, .jpeg'
                  onChange={(e) =>
                    e.target.files[0] && setImage(e.target.files[0])
                  }
                />
              </label>
            </div>
            <div>
              {image ? (
                <div>
                  <img
                    className='rounded-circle'
                    height={148}
                    width={140}
                    src={URL.createObjectURL(image)}
                    alt=''
                  />
                </div>
              ) : (
                <div>
                  <img
                    className='rounded-circle'
                    height={148}
                    width={140}
                    src={`${url}/get/image/freelancing_seller_files/${freelancingAuth?.photo}`}
                    alt=''
                  />
                </div>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-2'>
              <label className='fw-bold mb-1'>Enter your name</label>
              <input
                defaultValue={freelancingAuth.name}
                placeholder='Enter Name'
                type='text'
                className='Freelancing-login-input w-100'
                {...register('name')}
              />
            </div>
            <div className='mb-2'>
              <label className='fw-bold mb-1'>Enter your number</label>
              <input
                placeholder='01XXXXXXXXX'
                defaultValue={freelancingAuth.phone}
                type='number'
                className='Freelancing-login-input w-100'
                {...register('phone')}
              />
            </div>
            <div className='mb-2'>
              <label className='fw-bold mb-1'>Enter your email</label>
              <input
                placeholder='Enter Your Email'
                defaultValue={freelancingAuth.email}
                type='email'
                className='Freelancing-login-input w-100'
                {...register('email')}
              />
            </div>

            <div className=' pb-2 mt-3'>
              <div>
                <div>
                  <label className='fw-bold mb-1'>Enter your division</label>
                </div>
                <div>
                  <select
                    onClick={(e) => setDivisionID(e.target.value)}
                    className='Freelancing-login-input w-100 '
                    defaultValue={freelancingAuth.division}
                    {...register('division')}
                  >
                    <option value=''>Select Division</option>
                    <option value='Barisal'>Barisal</option>
                    <option value='Chittagong'>Chittagong</option>
                    <option value='Dhaka'>Dhaka</option>
                    <option value='Khulna'>Khulna</option>
                    <option value='Mymensingh'>Mymensingh</option>
                    <option value='Rajshahi'>Rajshahi</option>
                    <option value='Rangpur'>Rangpur</option>
                    <option value='Rajshahi'>Sylhet</option>
                  </select>
                </div>
              </div>
              <div>
                <div className='mt-2'>
                  <div>
                    <label className='fw-bold mb-1'>Enter your city</label>
                  </div>
                  <div>
                    <select
                      className='Freelancing-login-input w-100'
                      defaultValue={freelancingAuth.city}
                      {...register('city')}
                    >
                      {districts.map(
                        (dis) =>
                          divisionID === dis.division_name && (
                            <option value={dis.name} key={dis.id}>
                              {dis.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <label className='fw-bold mb-1'>Enter your bank name</label>
              <br />
              <select
                className='Freelancing-login-input w-100'
                defaultValue={freelancingAuth.bank_name}
                {...register('bank_name')}
              >
                <option value=''>Select Bank</option>
                <option value='nagad'>Nagad</option>
                <option value='bkash'>Baksh</option>
                <option value='upay'>Upay</option>
                <option value='rocket'>Rocket</option>
              </select>
            </div>

            <div className='mb-2'>
              <label className='fw-bold mb-1'>Enter your account aumber</label>
              <input
                placeholder='Enter Your Account Number'
                type='number'
                defaultValue={freelancingAuth.account_number}
                className='Freelancing-login-input w-100'
                {...register('account_number')}
              />
            </div>

            <div className='text-center mt-3'>
              <input type='submit' value='Update' className='login-submit' />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileInfoEdit;
