import React from 'react';
import { MdSms } from 'react-icons/md';

const FreelancingNoSms = () => {
  return (
    <>
      <div className='text-center mt-5'>
        <div>
          <MdSms size={60} />
        </div>
        <div>
          <h5 className='mb-2'>Select a Conversation</h5>
          <small>Try selecting a conversation</small>
        </div>
      </div>
    </>
  );
};

export default FreelancingNoSms;
