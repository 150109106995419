import React from 'react';
import { NavLink } from 'react-router-dom';
import { url } from '../../Helpers/Constant';

const FreelancingSingleConv = ({ sellerConv }) => {
  return (
    <>
      <NavLink
        to={`${sellerConv?.buyer_id}`}
        className={({ isActive }) => (isActive ? 'active-conv' : '')}
      >
        <div key={sellerConv?.id} className='mb-3 pe-2 '>
          <div className='ps-3 pe-2 pointer'>
            <img
              className='rounded-circle'
              width={46}
              height={50}
              src={
                sellerConv?.buyer_photo
                  ? `${url}/get/image/freelancing_buyer_files/${sellerConv?.buyer_photo}`
                  : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
              }
              alt=''
            />
          </div>
        </div>
      </NavLink>
    </>
  );
};

export default FreelancingSingleConv;
