import { Navigate } from 'react-router-dom';
import ShowSpinner from '../../../ShowSpinner/ShowSpinner';

import { useFreelancingAuth } from '../FreelancingaAuthContext';

const FreelancingPrivateRoute = ({ children }) => {
  const { freelancingAuth, isLoading } = useFreelancingAuth();
  if (isLoading) {
    return <ShowSpinner />;
  } else {
    return freelancingAuth.phone ? children : <Navigate to='/login' />;
  }
};

export default FreelancingPrivateRoute;
