import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FreelancingProfile from './FreelancingProfile';
import SellerMesssage from './SellerMesssage';
import FreelancingNavbar from './FreelancingNavbar';
import CreateGig from './CreateGig';
import FreelancingGig from './FreelancingGig';
import FreelancingJob from './FreelancingJob';
import FreelancingSingleGig from './FreelancingSingleGig';
import FreelancingSingleJob from './FreelancingSingleJob';
import FreelancingPrivateRoute from '../FreelancingAuthPage/FreelancingPrivateRoute/FreelancingPrivateRoute';

const FreelancingAccount = () => {
  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <FreelancingPrivateRoute>
              <FreelancingProfile />
            </FreelancingPrivateRoute>
          }
        ></Route>
        <Route
          path='/freelancing-message/*'
          element={<SellerMesssage />}
        ></Route>

        <Route path='/freelancing-gig' element={<FreelancingGig />}></Route>
        <Route
          path='/freelancing-single-gig/:id'
          element={<FreelancingSingleGig />}
        ></Route>
        <Route path='/create-gig' element={<CreateGig />}></Route>
        <Route path='/freelancing-jobs' element={<FreelancingJob />}></Route>
        <Route
          path='/freelancing-single-job/:id'
          element={<FreelancingSingleJob />}
        ></Route>
      </Routes>
      <div>
        <FreelancingNavbar />
      </div>
    </div>
  );
};

export default FreelancingAccount;
