import React, { useEffect, useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';

import { BiTimeFive } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import { url } from '../../Helpers/Constant';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FreelancingSingleGig = () => {
  const [singleGig, setSingleGIg] = useState({});
  const [gigImage, setGigImage] = useState([]);
  const { id } = useParams();

  const { freelancingAuth } = useFreelancingAuth();

  useEffect(() => {
    fetch(`${url}/out/api/gig/get/one/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSingleGIg(data.data);
          setGigImage(data.data.images);
        }
      });
  }, [id]);

  const { title, price, description, d_time, d_time_type, ft_img } = singleGig;

  return (
    <div className='mb-5'>
      <div className='header-div'>
        <h6 className='header-text'>
          <span className='pointer' onClick={() => window.history.back()}>
            <BsArrowLeftShort className='fs-2' />
          </span>
          Gig details
        </h6>
      </div>

      <div className='single-gig-body pt-4'>
        <div className='ps-3 '>
          <p className='text-white'>{title}</p>
          <div className='mt-3'>
            {freelancingAuth.photo ? (
              <img
                className='rounded-circle'
                width={30}
                height={30}
                src={`${url}/get/image/freelancing_seller_files/${freelancingAuth.photo}`}
                alt=''
              />
            ) : (
              <img
                className='rounded-circle'
                width={30}
                height={30}
                src='/assets/freelancing/profile-photo.png'
                alt=''
              />
            )}
            <small className='ps-2 text-white'>{freelancingAuth.name}</small>
          </div>
        </div>
        {/* gig images  */}
        <div className='mt-4 ps-1 pe-1 '>
          <Container>
            {gigImage.length ? (
              <Carousel>
                {gigImage.map((img, id) => {
                  return (
                    <Carousel.Item key={id}>
                      <LazyLoadImage
                        className='w-100'
                        style={{ objectFit: 'contain' }}
                        effect='blur'
                        height={350}
                        src={`${url}/get/image/freelancing_gig_files/${img.img_name}`}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            ) : (
              <div className='text-center'>
                <img
                  height={300}
                  src={`${url}/get/image/freelancing_gig_files/${ft_img}`}
                  alt=''
                />
              </div>
            )}
          </Container>
        </div>
        {/* gig price and description  */}
        <div className='ps-3 mt-5 pe-2'>
          <h5 className='text-white'>Price: {price} Tk</h5>
          <div className='d-flex gap-2 mt-4 align-items-center'>
            <div>
              <BiTimeFive className='text-white fs-4' />
            </div>
            <div className='d-flex gap-2'>
              <h5 className='text-white'>
                {d_time} {d_time_type} Delivery
              </h5>
            </div>
          </div>
          <div className='mb-5'>
            <p className='text-white mt-3 mb-5 gig-description '>
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreelancingSingleGig;
