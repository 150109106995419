import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { url } from '../../Helpers/Constant';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import { BsArrowLeftShort } from 'react-icons/bs';

const CreateGig = () => {
  const { register, handleSubmit, reset } = useForm();
  const { freelancingAuth } = useFreelancingAuth();
  const [skillsCategories, setSkillsCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skillid, setSkillId] = useState(null);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (item === 'skills') {
        formData.append(item, JSON.stringify(data[item]));
      } else {
        formData.append(item, data[item]);
      }
    });
    imgs.forEach((img, index) => {
      const name = 'img_' + (index + 1);
      formData.append(name, img);
    });

    // post new gig to server
    fetch(`${url}/out/api/seller/upload/gig/${freelancingAuth?.id}`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setError('');
          alert('gig create successfully');
          setImgs([]);
          reset();
          setIsLoading(false);
          navigate('/freelancing-account/freelancing-gig');
        } else {
          setError(data.message);
        }
      });
  };
  useEffect(() => {
    document.title = 'Create Gig | Ontheway';
  }, []);

  // get all skill Categories
  useEffect(() => {
    fetch(`${url}/out/api/seller/get/all/skill-categories`).then((res) =>
      res.json().then((data) => {
        setSkillsCategories(data.data);
      })
    );
  }, []);

  // get skills by skill catagory id
  const hanldeCategory = (id) => {
    fetch(`${url}/out/api/seller/get/all/skills/${id}`).then((res) =>
      res.json().then((data) => {
        setSkills(data.data);
        setSkillId(id);
      })
    );
  };

  const handelRemoveImg = (id) => {
    const removeImage = imgs?.filter((s, index) => index !== id);
    setImgs(removeImage);
  };
  return (
    <>
      <div className='header-div'>
        <h6 className='header-text'>
          <span className='pointer' onClick={() => window.history.back()}>
            <BsArrowLeftShort className='fs-2' />
          </span>
          Gig
        </h6>
      </div>
      <div className='gig-body-wraper'>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='fund pt-3 mx-3 pb-5'
          >
            <div>
              <label className='fw-bold pb-1 ps-2'>Gig Title</label>
              <br />
              <input
                {...register('title')}
                required
                className='create-gig-input w-100'
                placeholder='Gig Title'
              />
            </div>
            <div className='py-3'>
              <label className='fw-bold pb-1 ps-2'>Gig Description </label>
              <textarea
                style={{ height: '150px', paddingTop: '10px' }}
                required
                {...register('description')}
                className='create-gig-input w-100'
                placeholder='Gig Description'
              />
            </div>
            <div>
              <label className='fw-bold pb-1 ps-2'>
                Portfolio Link (www.example.com){' '}
              </label>
              <input
                {...register('pf_link')}
                className='create-gig-input w-100'
                placeholder='Portfolio Link (www.example.com)'
              />
            </div>
            <div className='py-3'>
              <label className='fw-bold pb-1 ps-2'>Gig price in (taka)</label>
              <br />
              <input
                {...register('price')}
                required
                type='number'
                className='create-gig-input  w-100'
                placeholder='Gig price in (taka) '
              />
            </div>

            <div className='d-flex align-items-center gap-5'>
              <div>
                <label className='fw-bold pb-1 ps-2'>Delivery Time</label>
                <div className='d-flex align-items-center '>
                  <div>
                    <input
                      {...register('d_time')}
                      required
                      type='number'
                      className='create-gig-input w-100'
                      placeholder='Delivery Time'
                      title='Delivery Time'
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className='fw-bold pb-1 ps-2'>Delivery Time</label>
                <select
                  {...register('d_time_type')}
                  className='create-gig-input '
                  required
                >
                  <option value='' disabled>
                    Choose Here
                  </option>
                  <option value='hour'>Hour</option>
                  <option value='day'>Day</option>
                  <option value='week'>Week</option>
                  <option value='month'>Month</option>
                </select>
              </div>
            </div>
            <div className='py-3'>
              <label className='fw-bold pb-1 ps-2'>
                Gig Category and skills
              </label>
              <div className='d-flex gap-5 '>
                <div>
                  <select
                    onClick={(e) => hanldeCategory(e.target.value)}
                    className='create-gig-input'
                    required
                    {...register('skill_category_id')}
                  >
                    {skillsCategories.map((catagory) => (
                      <option key={catagory.id} value={catagory.id}>
                        {catagory.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* skill   */}
                <div>
                  {skillid && (
                    <div
                      style={{
                        overflowY: 'scroll',
                        paddingRight: '10px',
                        height: '200px',
                      }}
                    >
                      {skills.map((skill) => {
                        const { id, name } = skill;
                        return (
                          <div
                            className='d-flex align-items-center gap-2 pb-2  '
                            key={id}
                          >
                            <input
                              {...register('skills')}
                              type='checkbox'
                              value={id}
                            />

                            <input
                              value={name}
                              disabled
                              type='text'
                              className='text-center skill-input'
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label className='fw-bold pb-1 ps-2'>
                Upload Thumbnail (up to 5)
              </label>
            </div>
            {imgs.length > 4 ? (
              <div>
                <div className='d-flex gap-2 '>
                  <div className='d-flex align-items-center gap-4 flex-wrap'>
                    {imgs.map((img, index) => {
                      return (
                        <div key={index} className='select-img-div'>
                          <img
                            className='gig-single-image'
                            src={URL.createObjectURL(img)}
                            alt=''
                          />
                          <div className='create-gig-remove-img '>
                            <button
                              onClick={() => handelRemoveImg(index)}
                              className='removeBtn'
                            >
                              x
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className='d-flex gap-2 '>
                  <div className='d-flex align-items-center gap-3 flex-wrap '>
                    {imgs.map((img, index) => {
                      return (
                        <div key={index} className='select-img-div'>
                          <img
                            className='gig-single-image'
                            src={URL.createObjectURL(img)}
                            alt=''
                          />
                          <div className='create-gig-remove-img '>
                            <button
                              onClick={() => handelRemoveImg(index)}
                              className='removeBtn'
                            >
                              x
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className='position-relative text-success '>
                      <label htmlFor='image' className='pointer'>
                        <div className='gig-up-image-input'>
                          <div className='mx-3 mt-2 '>
                            <img
                              className='mx-2'
                              src='/assets/freelancing/addImage.png'
                              alt=''
                            />
                            <h6>Add new</h6>
                          </div>
                        </div>
                        <input
                          className='d-none'
                          type='file'
                          id='image'
                          required
                          accept='.jpg, .png, .jpeg'
                          onChange={(e) =>
                            e.target.files[0] &&
                            setImgs([...imgs, e.target.files[0]])
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p className='text-danger text-center pt-3'>{error}</p>
            <br />
            <div className='mb-5 text-center'>
              {isLoading ? (
                <input
                  type='submit'
                  className='w-25'
                  value='Please wait...'
                  disabled
                />
              ) : (
                <input
                  className='gig-submit-btn w-25 text-white '
                  type='submit'
                  value='Submit'
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateGig;
