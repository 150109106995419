import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { url } from '../../Helpers/Constant';

import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import FreelancingNoSms from './FreelancingNoSms';
import FreelancingSingleConv from './FreelancingSingleConv';
import SellerSms from './SellerSms';

const SellerMesssage = () => {
  const { freelancingAuth } = useFreelancingAuth();
  const [allConv, setAllConv] = useState([]);

  useEffect(() => {
    fetch(`${url}/out/api/seller/get/all/convo/${freelancingAuth.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllConv(data.data);
        }
      });
  }, [freelancingAuth.id]);
  return (
    <>
      <div className='header-div'>
        <h6 className='header-text'>Chat</h6>
      </div>
      <div className='d-flex'>
        <div className='freelancing-all-conv-section pt-3 pe-2 '>
          {allConv?.length ? (
            allConv?.map((sellerConv) => (
              <FreelancingSingleConv
                key={sellerConv.id}
                sellerConv={sellerConv}
              />
            ))
          ) : (
            <div>
              <img
                className='rounded-circle ms-3 me-4'
                width={46}
                height={50}
                src='/assets/noconv.png'
                alt=''
              />
            </div>
          )}
        </div>
        <div className='w-100'>
          <Routes>
            <Route
              path='/'
              element={<FreelancingNoSms></FreelancingNoSms>}
            ></Route>
            <Route path='/:buyer_id' element={<SellerSms></SellerSms>}></Route>
          </Routes>
        </div>
      </div>
    </>
  );
};

export default SellerMesssage;
