import React, { useState } from 'react';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';
import { SiFreelancer } from 'react-icons/si';
import { url } from '../../Helpers/Constant';
import ProfileInfoEdit from './ProfileInfoEdit';

const FreelancingProfile = () => {
  const { freelancingAuth, setFreelancingAuth } = useFreelancingAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handelLogout = () => {
    setFreelancingAuth({});
    sessionStorage.removeItem('_f_o');
  };

  return (
    <>
      <div className='header-div'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <h6 className='profile-header-text'>Profile Information</h6>
          </div>
          <div className='mt-4 pe-4'>
            <a
              href='https://freelancing.onthe-way.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <SiFreelancer
                className='fs-3 text-white'
                title='Freelancing website'
              />
            </a>
          </div>
        </div>
      </div>
      <div className='main-body-wraper'>
        <div>
          <div className='text-end pt-2 pe-3 '>
            <button className='freelancer-logout-button' onClick={handelLogout}>
              Log out
            </button>
          </div>
          <div className='text-center pt-2 pb-4'>
            {freelancingAuth?.photo ? (
              <div>
                <img
                  className='rounded-circle'
                  height={120}
                  width={120}
                  src={`${url}/get/image/freelancing_seller_files/${freelancingAuth?.photo}`}
                  alt=''
                />
              </div>
            ) : (
              <div>
                <img
                  className='rounded-circle'
                  height={148}
                  width={140}
                  src='/assets/freelancing/profile-photo.png'
                  alt=''
                />
              </div>
            )}
          </div>
          <div>
            <div className='profile-info-wraper d-flex align-items-center position-relative'>
              <div>
                <img
                  className='img-fluid'
                  src='/assets/freelancing/curveback.png'
                  alt=''
                />
                <div className='information'>
                  <h4>{freelancingAuth.name}</h4>
                  <h5>OTW Freelancer</h5>
                  {/* <h6 className='my-1'>(Hire Rate) </h6> */}
                  <h6 className='mt-1'>{`0${freelancingAuth.phone}`}</h6>
                  <img
                    className='w-100 pe-4 mt-4 mb-3'
                    src='/assets/vector.jpg'
                    alt=''
                  />
                  {/* my-order Completed and pending  */}
                  <div className='d-flex justify-content-evenly text-center order-review'>
                    <div>
                      <p>Completed</p>
                      <span>0</span>
                    </div>
                    <div>
                      <p>Pending</p>
                      <span>0</span>
                    </div>
                    <div>
                      <p>Available</p>
                      <span>0</span>
                    </div>
                    <div>
                      <p>Total Earn</p>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* bank name and number  */}
          <div className='bank-section-wraper mx-auto my-4 pb-5 '>
            <div className='mx-4 pt-3 bank-account-section'>
              <div>
                <h3>Bank Name</h3>
                {freelancingAuth?.bank_name ? (
                  <p>{freelancingAuth?.bank_name}</p>
                ) : (
                  <p>Bkash</p>
                )}
              </div>
              <div>
                <h3>Account no</h3>
                {freelancingAuth?.account_number ? (
                  <p>{freelancingAuth?.account_number}</p>
                ) : (
                  <p>XXXXXXXXXX</p>
                )}
              </div>
            </div>
          </div>

          {/* profile edit section  */}
          <div className='text-end pe-2  pb-5 '>
            <button className='profileEdit-btn mb-5 ' onClick={handleShow}>
              Edit
            </button>
          </div>
        </div>
      </div>

      <ProfileInfoEdit show={show} handleClose={handleClose} />
    </>
  );
};

export default FreelancingProfile;
