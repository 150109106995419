import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../../Helpers/Constant';
import '../Freelancing.css';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';

const AdminFreelancing = () => {
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const { setFreelancingAuth } = useFreelancingAuth();
  const navigate = useNavigate();

  const handelSellerLogIn = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    if (phone.length === 11 && phone.startsWith('01')) {
      if (password.length >= 6) {
        fetch(`${url}/out/api/auth/seller/login`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ phone, password }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setFreelancingAuth(data.data);
              setIsLoading(false);
              navigate('/');
            } else {
              setIsLoading(false);
              setError('Wrong Phone or Password');
              setTimeout(() => {
                setError('');
              }, 5000);
            }
          });
      } else {
        setIsLoading(false);
        setError('Wrong Password');
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    } else {
      setError('Invaild phone Number');
      setIsLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };
  return (
    <div className='freelancing-login-page'>
      <div className='py-4'>
        <div className='text-center'>
          <div>
            <img
              className='freelancing-img'
              src='/assets/freelancing/logo.png'
              alt=''
            />
            <p className='freelancing-text '>Q-Freelancing</p>
            {/* banner image */}
            <div className='mt-5'>
              <img src='/assets/freelancing/freelancing.png' alt='' />
            </div>
          </div>
        </div>
        {/* log ing div  */}

        <div className='mt-3 pb-2 login-section px-4'>
          <div className='text-center  pt-1 '>
            <h4>Login </h4>
          </div>
          <div className='mt-3 px-1'>
            <form onSubmit={handelSellerLogIn}>
              <div>
                <div>
                  <label className='fw-bold mb-1'>Enter your number</label>
                  <input
                    placeholder='01XXXXXXXXX'
                    type='text'
                    className='Freelancing-login-input w-100'
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className='mt-3'>
                  <label className='fw-bold mb-1'>Enter your password</label>
                  <input
                    placeholder='Enter Your Password'
                    type={passwordType}
                    className='Freelancing-login-input w-100'
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className='mt-2 ps-1'>
                  <input
                    type='checkbox'
                    onClick={() => {
                      passwordType === 'password'
                        ? setPasswordType('text')
                        : setPasswordType('password');
                    }}
                  />
                  <span className='ps-1'>Show password</span>
                </div>

                {error && (
                  <div className='mt-3'>
                    {error && (
                      <p className='text-center text-danger'>{error}</p>
                    )}
                  </div>
                )}

                <div className='text-center mt-2'>
                  {isLoading ? (
                    <input
                      type='submit'
                      className='login-submit '
                      value='Login...'
                      disabled
                    />
                  ) : (
                    <input
                      type='submit'
                      className='login-submit mb-2'
                      value='Login'
                    />
                  )}
                </div>

                <hr />
                <div className='text-center'>
                  <Link to='/forgot-password' className='text-decoration-none'>
                    Forgot password?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='my-3 text-center'>
          Not register?{' '}
          <Link className='text-decoration-none' to='/register'>
            Register{' '}
          </Link>
          <br />
          or{' '}
          <Link
            to='/registerwith-queenaccount'
            className='text-decoration-none'
          >
            {' '}
            Register with queen account
          </Link>
        </div>

        {/* user/not  */}
        <div className='text-des mx-4 text-muted '>
          <p>
            If you can't log in with your queen app phone number and password
            then download the freelancing app apply for the seller then try to
            log in or you can use the Website/Apps
          </p>
          <div className='mt-3 text-center'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://queen.onthe-way.com/'
              className='text-decoration-none pe-4'
            >
              {' '}
              Use website
            </a>

            <a
              className=' text-decoration-none '
              href='https://play.google.com/store/apps/details?id=com.m360ict.ontheway_queen'
              target='_blank'
              rel='noreferrer'
            >
              Download Queen App
            </a>
          </div>
          <div className='text-center my-3'>
            <a
              href='https://play.google.com/store/apps/details?id=com.on_the_way.freelancing'
              target='_blank'
              rel='noopener noreferrer'
              className='text-decoration-none'
            >
              Download freelancing app
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFreelancing;
