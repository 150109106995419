import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { url } from '../../Helpers/Constant';

import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';

const FreelancingJob = () => {
  const { freelancingAuth } = useFreelancingAuth();
  const [allOrder, setAllOrder] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${url}/out/api/gig-order/get/all/seller/${freelancingAuth.id}?limit=10&skip=0`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllOrder(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  }, [freelancingAuth.id]);
  return (
    <>
      <div className='header-div'>
        <h6 className='header-text'>Job Offer</h6>
      </div>

      <div className='freelancing-jobs-body-wrapper'>
        {loading ? (
          <div className='text-center mt-5 '>
            <Spinner animation='border' style={{ color: '#e9313a' }} />
          </div>
        ) : (
          <div>
            {allOrder?.length ? (
              <div>
                {allOrder?.map((singleOrder) => {
                  const {
                    id,
                    buyer_name,
                    buyer_photo,
                    gig_ft_img,
                    gig_title,
                    status,
                    offer_price,
                  } = singleOrder;
                  return (
                    <div key={id} className='mx-2 mt-3 pb-3'>
                      <div className='show-gig-div'>
                        <img
                          className='w-100'
                          height={200}
                          src={`${url}/get/image/freelancing_gig_files/${gig_ft_img}`}
                          alt=''
                        />
                        <div className='d-flex justify-content-between align-items-center gig-detail ps-1 py-2 '>
                          <div className='d-flex gap-2'>
                            <div>
                              <img
                                className='rounded-circle'
                                width={30}
                                height={30}
                                src={`${url}/get/image/queens/${freelancingAuth?.photo}`}
                                alt=''
                              />
                            </div>
                            <div className='text-white'>
                              <p className='gig title'>{gig_title}</p>
                              {status === 'Approved' && (
                                <h6 className='text-success fw-bold mt-1'>
                                  {status}
                                </h6>
                              )}
                              {status === 'Pending' && (
                                <h6 className='text-warning fw-bold mt-1'>
                                  {status}
                                </h6>
                              )}
                            </div>
                          </div>
                          <div>
                            <h6 className='text-white pe-1 gig-price'>
                              STARTING AT{' '}
                              <span className='fw-bold'>{offer_price} tk</span>{' '}
                            </h6>
                          </div>
                        </div>
                        <div className='seller-gig-order '>
                          <div className='text-center'>
                            <img
                              className='rounded-circle'
                              width={50}
                              height={60}
                              src={`${url}/get/image/customers/${buyer_photo}`}
                              alt=''
                            />
                            <h4 className='text-black mb-2'>
                              {buyer_name},{' '}
                              <small>Offer you a job in {offer_price} tk</small>
                            </h4>{' '}
                            <Link
                              className=' text-decoration-none pointer freelancing-order-details-link  '
                              to={`/freelancing-account/freelancing-single-job/${id}`}
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='text-center mt-5 align-items-center'>
                <h3>No Job offer for you</h3>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FreelancingJob;
