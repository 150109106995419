import React from 'react';
import { url } from '../../Helpers/Constant';
import { useFreelancingAuth } from '../FreelancingAuthPage/FreelancingaAuthContext';

const ShowSellerSms = ({ singleSms, singleConv }) => {
  const { freelancingAuth } = useFreelancingAuth();

  const { message, atc, sender_id, reciever_id } = singleSms;

  return (
    <div>
      <div
        className={
          freelancingAuth.id === sender_id
            ? 'text-end ps-1 '
            : 'text-start ps-1'
        }
      >
        {message && atc.length ? (
          <div className='mt-3 mb-4'>
            <div className='me-2'>
              <p
                style={{ marginLeft: '35px' }}
                className={
                  freelancingAuth.id === sender_id
                    ? 'text-end  Seller-sms  '
                    : 'text-start buyer-sms '
                }
              >
                {message}
              </p>
              <div
                className={
                  freelancingAuth.id === sender_id ? 'text-end' : 'text-start'
                }
              >
                <div
                  className={
                    freelancingAuth.id === sender_id
                      ? 'd-flex flex-wrap align-items-center justify-content-end'
                      : 'd-flex flex-wrap align-items-center justify-content-start'
                  }
                >
                  {reciever_id === freelancingAuth.id && (
                    <img
                      className='rounded-circle mt-5'
                      width={30}
                      height={30}
                      src={
                        singleConv?.buyer_photo
                          ? `${url}/get/image/freelancing_buyer_files/${singleConv?.buyer_photo}`
                          : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
                      }
                      alt=''
                    />
                  )}
                  {atc?.map((img, index) => {
                    if (typeof img === 'object') {
                      return (
                        <div key={index} className='ps-2 pe-2'>
                          <img
                            width={100}
                            height={80}
                            src={URL.createObjectURL(img)}
                            alt=''
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index}
                          style={{ marginTop: '8px' }}
                          className={
                            freelancingAuth.id === sender_id
                              ? 'text-end pe-2'
                              : 'text-start'
                          }
                        >
                          <div>
                            <img
                              className='ps-2'
                              width={100}
                              height={80}
                              src={`${url}/get/image/freelancing_convo_files/${img}`}
                              alt=''
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                {/* send by me or not  */}
                <div className={`text-muted small pe-2 `}>
                  {freelancingAuth.id === sender_id ? 'You' : ''}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {!message ? (
              <div
                className={
                  freelancingAuth.id === sender_id ? 'text-end' : 'text-start'
                }
              >
                <div>
                  <div
                    className={
                      freelancingAuth.id === sender_id
                        ? 'd-flex flex-wrap align-items-center justify-content-end'
                        : 'd-flex flex-wrap align-items-center justify-content-start'
                    }
                  >
                    {reciever_id === freelancingAuth.id && (
                      <img
                        className='rounded-circle mt-5'
                        width={30}
                        height={30}
                        src={
                          singleConv?.buyer_photo
                            ? `${url}/get/image/freelancing_buyer_files/${singleConv?.buyer_photo}`
                            : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
                        }
                        alt=''
                      />
                    )}
                    {atc?.map((img, index) => {
                      if (typeof img === 'object') {
                        return (
                          <div key={index} className='ps-2 pe-2'>
                            <img
                              style={{ marginTop: '25px' }}
                              width={100}
                              height={80}
                              src={URL.createObjectURL(img)}
                              alt=''
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            style={{ marginTop: '25px' }}
                            className={
                              freelancingAuth.id === sender_id
                                ? 'text-end pe-2'
                                : 'text-start'
                            }
                          >
                            <div>
                              <img
                                className='ps-2'
                                width={100}
                                height={80}
                                src={`${url}/get/image/freelancing_convo_files/${img}`}
                                alt=''
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                {/* send by me or not  */}
                <div className={`text-muted small pe-2 `}>
                  {freelancingAuth.id === sender_id ? 'You' : ''}
                </div>
              </div>
            ) : (
              <div className='me-2'>
                <div>
                  {reciever_id === freelancingAuth.id && (
                    <img
                      className='rounded-circle'
                      width={30}
                      height={30}
                      src={
                        singleConv?.buyer_photo
                          ? `${url}/get/image/freelancing_buyer_files/${singleConv?.buyer_photo}`
                          : 'https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-anon-female-icon.jpg'
                      }
                      alt=''
                    />
                  )}

                  <p
                    className={
                      freelancingAuth.id === sender_id
                        ? 'text-end  Seller-sms  '
                        : 'text-start  buyer-sms'
                    }
                  >
                    {message}
                  </p>
                  <div className={`text-muted small pe-2 `}>
                    {freelancingAuth.id === sender_id ? 'You ' : ''}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowSellerSms;
