import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ShowSellerSms from './ShowSellerSms';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { url } from '../../Helpers/Constant';
import { Spinner } from 'react-bootstrap';

const SellerSmsSection = ({ singleConv }) => {
  const { register, handleSubmit, reset } = useForm();
  const [allSms, setAllSms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${url}/out/api/buyer/get/all/messages/${singleConv?.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setAllSms(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, [singleConv?.id]);

  const onSubmit = (data) => {
    data.sender_id = singleConv?.seller_id;
    data.reciever_id = singleConv?.buyer_id;

    const fromData = new FormData();

    Object.keys(data).forEach((item) => {
      fromData.append(item, data[item]);
    });
    if (files.length) {
      files.forEach((file, index) => {
        const name = 'file' + (index + 1);
        fromData.append(name, file);
      });
    }
    fetch(`${url}/out/api/convo/send/message/${singleConv?.id}`, {
      method: 'POST',
      body: fromData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          data.atc = files;
          setAllSms((preSms) => [...preSms, data]);
          reset();
          setFiles([]);
        }
      });
  };

  const handelRemoveImag = (id) => {
    const removeImg = files?.filter((s, index) => index !== id);
    setFiles(removeImg);
  };
  return (
    <div>
      <div className='mb-5 mt-3'>
        {loading ? (
          <div className='text-center mt-5'>
            <Spinner animation='border' style={{ color: '#e9313a' }} />
          </div>
        ) : (
          <div className='sms-show'>
            {allSms?.length ? (
              <div>
                {allSms?.map((singleSms, index) => {
                  const lastMessage = allSms.length - 1 === index;
                  return (
                    <div ref={lastMessage ? setRef : null} key={index}>
                      <ShowSellerSms
                        singleConv={singleConv}
                        singleSms={singleSms}
                        key={index}
                      ></ShowSellerSms>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='text-center mt-5'>
                <p>No sms yet!</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='ps-4  mb-4 position-absolute seller-sms-input-wrapper '>
        <div className='pb-2'>
          <div>
            <div className='d-flex align-items-center gap-2 flex-wrap'>
              {files.length && (
                <div className='d-flex align-items-center gap-2 flex-wrap'>
                  {files?.map((img, index) => {
                    return (
                      <div key={index}>
                        <div className='select-img-div'>
                          <img
                            height={50}
                            src={URL.createObjectURL(img)}
                            alt=''
                          />

                          <button
                            className='removeBtn remove-img'
                            onClick={() => handelRemoveImag(index)}
                          >
                            x
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex align-items-center gap-3   '>
            <div className='position-relative '>
              <label htmlFor='image' className='pointer'>
                <MdOutlineCloudUpload
                  color='#f66391'
                  size={35}
                  title='Upload file or image'
                />

                <input
                  className='d-none'
                  type='file'
                  id='image'
                  onChange={(e) =>
                    e.target.files[0] && setFiles([...files, e.target.files[0]])
                  }
                />
              </label>
            </div>
            <div className='d-flex align-items-center'>
              <input
                type='text'
                className='seller-sms-input ps-2 '
                {...register('message')}
                placeholder='Type your message'
              />
              <button type='submit' className='seller-sms-submit-button'>
                send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SellerSmsSection;
