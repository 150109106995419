import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { AiFillMessage } from 'react-icons/ai';
import { BsFillBagFill } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineCreate } from 'react-icons/md';

const FreelancingNavbar = () => {
  const location = useLocation();
  const location2 = location.pathname.split('/freelancing-account/')[1];

  return (
    <div className='freelancer-header-wrapper '>
      <Row>
        <Col md={4} className='mx-auto'>
          <div className='d-flex justify-content-evenly freelancer-header mb-3'>
            <div>
              <Link to='/freelancing-account'>
                <CgProfile
                  className={
                    location.pathname === '/freelancing-account'
                      ? 'active-nav-btn'
                      : 'fs-3'
                  }
                  title='Account'
                />
              </Link>
            </div>
            <div>
              <Link to='/freelancing-account/freelancing-gig'>
                <MdOutlineCreate
                  title='Create gig'
                  className={
                    location2 === 'freelancing-gig' ? 'active-nav-btn' : 'fs-3'
                  }
                />
              </Link>
            </div>
            <div>
              <Link to='/freelancing-account/freelancing-jobs'>
                <BsFillBagFill
                  className={
                    location2 === 'freelancing-jobs' ? 'active-nav-btn' : 'fs-3'
                  }
                  title='Job Offer'
                />
              </Link>
            </div>

            <div>
              <Link to='/freelancing-account/freelancing-message'>
                <AiFillMessage
                  className={
                    location2 === 'freelancing-message'
                      ? 'active-nav-btn'
                      : 'fs-3'
                  }
                  title='Create-gig'
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FreelancingNavbar;
