import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BsArrowLeftShort } from 'react-icons/bs';
import { AiOutlineDownload } from 'react-icons/ai';
import { url } from '../../Helpers/Constant';

const FreelancingSingleJob = () => {
  const [singleOffer, setSingleOffer] = useState({});
  const [success, setSucces] = useState('');

  const { id } = useParams();

  const { buyer_name, gig_title, order_details, attachment, status } =
    singleOffer;

  // load data from server
  useEffect(() => {
    fetch(`${url}/out/api/gig-order/get/single/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSingleOffer(data.data);
        }
      });
  }, [id]);

  // handel accept
  const handelAccept = (orderId) => {
    fetch(`${url}/out/api/gig-order/update/status/${orderId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: 'Accept', statusChangedBy: 'seller' }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSucces(data.message);
          setTimeout(() => {
            setSucces('');
          }, '5000');
        }
      });
  };
  // handel reject
  const handelReject = (orderId) => {
    fetch(`${url}/out/api/gig-order/update/status/${orderId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: 'Reject', statusChangedBy: 'seller' }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSucces(data.message);
          setTimeout(() => {
            setSucces('');
          }, '5000');
        }
      });
  };

  return (
    <>
      <div className='header-div'>
        <h6 className='header-text'>
          <span className='pointer' onClick={() => window.history.back()}>
            <BsArrowLeftShort className='fs-2' />
          </span>{' '}
          Job Offer
        </h6>
      </div>
      <div className='single-job-offer-datails text-white px-4 pt-4 '>
        <div>
          <h4 className='text-white'>{buyer_name}</h4>
          <div className='mt-3'>
            <h5>{gig_title}</h5>
            <p className='mt-3 gig-description '>{order_details}</p>
          </div>
        </div>
        {attachment && (
          <div className='d-flex align-items-center gap-3 mt-5'>
            <img
              width={50}
              height={20}
              src={`${url}/get/image/freelancing_gig_order_files/${attachment}`}
              alt=''
            />
            <p>{attachment}</p>

            <a
              href={`${url}/download/files/freelancing_gig_order_files/${attachment}`}
            >
              <AiOutlineDownload className='fs-4 pointer' />
            </a>
          </div>
        )}

        <div className='mt-4'>
          <p className='text-white'>{success}</p>
        </div>
        {status === 'Accept' ? (
          ''
        ) : (
          <div className='text-center mt-3'>
            <div>
              <button
                className='joboffer-accept-button'
                onClick={() => handelAccept(id)}
              >
                Accept
              </button>
            </div>

            <div className='mt-4'>
              <button
                className='joboffer-reject-button'
                onClick={() => handelReject(id)}
              >
                Reject
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FreelancingSingleJob;
