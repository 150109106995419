import { createContext, useContext, useEffect, useState } from 'react';

const FreelancingAuthContext = createContext();

const FreelancingContextProvider = ({ children }) => {
  const [freelancingAuth, setFreelancingAuth] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setFreelancingAuthData = (data) => {
    sessionStorage.setItem('_f_o', JSON.stringify(data));
    setFreelancingAuth(data);
  };
  useEffect(() => {
    const freelancer = sessionStorage.getItem('_f_o');
    if (freelancer) {
      const nowFreelancer = JSON.parse(freelancer);
      setFreelancingAuth(nowFreelancer);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [freelancingAuth.phone]);

  return (
    <FreelancingAuthContext.Provider
      value={{
        freelancingAuth,
        setFreelancingAuth: setFreelancingAuthData,
        isLoading,
      }}
    >
      {children}
    </FreelancingAuthContext.Provider>
  );
};
const useFreelancingAuth = () => {
  return useContext(FreelancingAuthContext);
};
export { useFreelancingAuth, FreelancingContextProvider };
